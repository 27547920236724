import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { ClipBoard } from 'components/common/ClipBoard/ClipBoard';
import { sortingPaymentMethods } from 'components/common/PaymentForm/utils';
import {
  BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE,
  CURRENCY_TYPES,
  PAYMENT_PROVIDERS,
  ACCOUNT_PROPERTIES,
  MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE,
  PAYMENT_METHOD
} from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { amountFormattedValue } from 'services/utils';
import './PopUpScheme.scss';

const PopUpScheme = ({ activeAccount, onClose, userStore }) => {
  const i18n = useContext(i18nContext);

  const schemeLine = (left, center) => {
    return (
      <div key={left} className={'scheme-line'}>
        <p className={'left'}>{left}</p>
        <p className={'center'}>{center}</p>
        <ClipBoard copyText={center} />
      </div>
    );
  };

  const getPaymentMethods = () => {
    const sortedMethods = sortingPaymentMethods(
      activeAccount?.payment_methods,
      activeAccount?.currency,
      activeAccount?.transfer_provider === PAYMENT_PROVIDERS.MANUAL
        ? MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE
        : BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE
    );

    return sortedMethods.map((method) => i18n.getMessage(`transfer.paymentMethod.${method}`)).join(', ');
  };

  const commonAccountDetailsScheme = [
    {
      left: i18n.getMessage('popUp.left.accountHolder'),
      center: userStore.userData.account.common_name
    },
    activeAccount.external_wallet_number
      ? {
          left: i18n.getMessage('popUp.left.externalAccountNumber'),
          center: activeAccount.external_wallet_number
        }
      : null,
    activeAccount.bank_details
      ? {
          left: i18n.getMessage('popUp.left.bankDetails'),
          center: activeAccount.bank_details
        }
      : null,
    activeAccount?.iban?.iban || activeAccount.multi_iban?.iban
      ? {
          left: i18n.getMessage('popUp.left.IBAN'),
          center: activeAccount?.iban?.iban || activeAccount.multi_iban?.iban
        }
      : activeAccount?.transfer_provider === PAYMENT_PROVIDERS.MANUAL ||
          activeAccount?.transfer_provider === PAYMENT_PROVIDERS.WALLESTER
        ? {
            left: i18n.getMessage(`popUp.left.${activeAccount?.transfer_provider}`),
            center: activeAccount?.wallet_number
          }
        : null,
    activeAccount.currency === CURRENCY_TYPES.GBP &&
    (activeAccount?.iban?.sort_code || activeAccount.multi_iban?.sort_code)
      ? {
          left: i18n.getMessage('popUp.left.sortCode'),
          center: activeAccount?.iban?.sort_code || activeAccount.multi_iban?.sort_code
        }
      : null,
    activeAccount.currency === CURRENCY_TYPES.GBP &&
    (activeAccount?.iban?.account_number || activeAccount.multi_iban?.account_number)
      ? {
          left: i18n.getMessage('popUp.left.accountNumber'),
          center: activeAccount?.iban?.account_number || activeAccount.multi_iban?.account_number
        }
      : null,
    activeAccount.bank_country
      ? {
          left: i18n.getMessage('popUp.left.country'),
          center: activeAccount.bank_country
        }
      : null,
    activeAccount.bank_name
      ? {
          left: i18n.getMessage('popUp.left.bankName'),
          center: activeAccount.bank_name
        }
      : null,
    activeAccount.bank_address
      ? {
          left: i18n.getMessage('popUp.left.bankAddress'),
          center: activeAccount.bank_address
        }
      : null,
    activeAccount.bic
      ? {
          left: i18n.getMessage('popUp.left.BIC'),
          center: activeAccount.bic
        }
      : null,
    activeAccount?.routing_codes?.aba
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.aba'),
          center: activeAccount.routing_codes.aba
        }
      : null,
    activeAccount?.routing_codes?.blz_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.blz'),
          center: activeAccount.routing_codes.blz_code
        }
      : null,
    activeAccount?.routing_codes?.bsb_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.bsb'),
          center: activeAccount.routing_codes.bsb_code
        }
      : null,
    activeAccount?.routing_codes?.clabe_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.clabe'),
          center: activeAccount.routing_codes.clabe_code
        }
      : null,
    activeAccount?.routing_codes?.ctn
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.ctn'),
          center: activeAccount.routing_codes.ctn
        }
      : null,
    activeAccount?.routing_codes?.ifs_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.ifs'),
          center: activeAccount.routing_codes.ifs_code
        }
      : null,
    activeAccount?.routing_codes?.sort_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.sort'),
          center: activeAccount.routing_codes.sort_code
        }
      : null,
    {
      left: i18n.getMessage('popUp.left.paymentMethod'),
      center: getPaymentMethods()
    }
  ];

  const manualAccountDetailsScheme = [
    activeAccount.wallet_number
      ? {
          left: i18n.getMessage('popUp.left.accountNumber'),
          center: activeAccount.wallet_number
        }
      : null,
    getPaymentMethods().includes(PAYMENT_METHOD.SWIFT) &&
    (activeAccount?.iban?.iban === 'GB05IFXS23229006273629' ||
      activeAccount.multi_iban?.iban === 'GB05IFXS23229006273629')
      ? {
          left: i18n.getMessage('popUp.left.accountHolder'),
          center: process.env.REACT_APP_FIN
        }
      : getPaymentMethods().includes(PAYMENT_METHOD.SWIFT)
        ? {
            left: i18n.getMessage('popUp.left.accountHolder'),
            center: userStore?.userData?.account?.common_name
          }
        : {
            left: i18n.getMessage('popUp.left.accountHolder'),
            center: activeAccount.account_holder
          },
    activeAccount?.iban?.iban || activeAccount.multi_iban?.iban
      ? {
          left: i18n.getMessage('popUp.left.IBAN'),
          center: activeAccount?.iban?.iban || activeAccount.multi_iban?.iban
        }
      : null,
    {
      left: i18n.getMessage('popUp.left.country'),
      center: activeAccount.bank_country
    },
    {
      left: i18n.getMessage('popUp.left.paymentServiceProviderName'),
      center: activeAccount.bank_name
    },
    {
      left: i18n.getMessage('popUp.left.address'),
      center: activeAccount.bank_address
    },
    {
      left: i18n.getMessage('popUp.left.bicAndSwift'),
      center: activeAccount.bic
    },
    activeAccount.currency === CURRENCY_TYPES.EUR && getPaymentMethods().includes(PAYMENT_METHOD.SEPA)
      ? {
          left: i18n.getMessage('popUp.left.reference'),
          center: `${process.env.REACT_APP_MANUAL_PROVIDER_EUR_EUROPEAN_REFERENCE}, ${activeAccount.wallet_number}, ${
            userStore.userData.account.common_name
          }, ${i18n.getMessage('popUp.right.referenceEnd')}`
        }
      : getPaymentMethods().includes(PAYMENT_METHOD.SWIFT) &&
          (activeAccount?.iban?.iban === 'GB05IFXS23229006273629' ||
            activeAccount.multi_iban?.iban === 'GB05IFXS23229006273629')
        ? {
          left: i18n.getMessage('popUp.left.reference'),
          center: `${activeAccount.wallet_number}, ${i18n.getMessage('popUp.right.referenceEnd')}`
        }
        : getPaymentMethods().includes(PAYMENT_METHOD.SWIFT)
          ? null
          : {
              left: i18n.getMessage('popUp.left.reference'),
              center: `${activeAccount.wallet_number}, ${userStore.userData.account.common_name}, ${i18n.getMessage(
                'popUp.right.referenceEnd'
              )}`
            },
    {
      left: i18n.getMessage('popUp.left.paymentMethod'),
      center: getPaymentMethods()
    }
  ];

  const ryvylAccountDetailsScheme = [
    activeAccount.wallet_number
      ? {
          left: i18n.getMessage('popUp.left.accountNumber'),
          center: activeAccount.wallet_number
        }
      : null,
    {
      left: i18n.getMessage('popUp.left.accountHolder'),
      center: userStore.userData.account.common_name
    },
    activeAccount?.iban?.iban || activeAccount.multi_iban?.iban
      ? {
          left: i18n.getMessage('popUp.left.IBAN'),
          center: activeAccount?.iban?.iban || activeAccount.multi_iban?.iban
        }
      : null,
    {
      left: i18n.getMessage('popUp.left.country'),
      center: activeAccount.bank_country
    },
    {
      left: i18n.getMessage('popUp.left.paymentServiceProviderName'),
      center: activeAccount.bank_name
    },
    {
      left: i18n.getMessage('popUp.left.address'),
      center: activeAccount.bank_address
    },
    {
      left: i18n.getMessage('popUp.left.bicAndSwift'),
      center: activeAccount.bic
    },
    {
      left: i18n.getMessage('popUp.left.paymentMethod'),
      center: getPaymentMethods()
    }
  ];

  const getAccountDetailsScheme = () => {
    if (activeAccount?.transfer_provider === PAYMENT_PROVIDERS.MANUAL) {
      return manualAccountDetailsScheme;
    } else if (activeAccount?.transfer_provider === PAYMENT_PROVIDERS.RYVYL) {
      return ryvylAccountDetailsScheme;
    }
    return commonAccountDetailsScheme;
  };

  return (
    <div className={'scheme-wrapper account-details'}>
      <div className={'scheme-header'}>
        <p className={'account'}>{i18n.getMessage('tooltip.accountDetails')}</p>
        <CloseIcon className={'scheme-close-icon'} onClick={onClose} />
      </div>
      <div className={'wrapper-balance'}>
        {(activeAccount.iban?.iban || activeAccount.multi_iban?.iban) && (
          <p className={'scheme-iban'}>{activeAccount.iban?.iban || activeAccount.multi_iban?.iban}</p>
        )}
        <div className={'balance-wrapper'}>
          <p className={'scheme-balance'}>{amountFormattedValue(activeAccount[ACCOUNT_PROPERTIES.AVAILABLE])}</p>
          <p className={'scheme-currency'}>{activeAccount[ACCOUNT_PROPERTIES.CURRENCY]}</p>
        </div>
        <p className={'scheme-description'}>
          {i18n.getMessage('popUp.description', { appTitle: process.env.REACT_APP_PAYMENT_INSTITUTION })}
        </p>
      </div>
      {getAccountDetailsScheme().map((line) => line && schemeLine(line.left, line.center))}
    </div>
  );
};

PopUpScheme.propTypes = {
  activeAccount: PropTypes.object,
  onClose: PropTypes.func,
  userStore: MobXPropTypes.observableObject
};

export default inject('userStore')(observer(PopUpScheme));
